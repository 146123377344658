
.border {
    background-size: 19px 100%;
    background-repeat: no-repeat;

    &--right {
        background-position: right top;
    }

    &--left {
        background-position: left top;
    }

    &--second {
        background-image: linear-gradient($color-second 0, $color-second 14.286%, transparent 14.286%, transparent 28.572%, $color-second 28.572%, $color-second 42.858%, transparent 42.858%, transparent 57.144%, $color-second 57.144%, $color-second 71.43%, transparent 71.43%, transparent 85.716%, $color-second 85.716%, $color-second 100%);
    }

    &--main {
        background-image: linear-gradient($color-main 0, $color-main 14.286%, transparent 14.286%, transparent 28.572%, $color-main 28.572%, $color-main 42.858%, transparent 42.858%, transparent 57.144%, $color-main 57.144%, $color-main 71.43%, transparent 71.43%, transparent 85.716%, $color-main 85.716%, $color-main 100%);
    }

    &--white {
        background-image: linear-gradient($color-white 0, $color-white 14.286%, transparent 14.286%, transparent 28.572%, $color-white 28.572%, $color-white 42.858%, transparent 42.858%, transparent 57.144%, $color-white 57.144%, $color-white 71.43%, transparent 71.43%, transparent 85.716%, $color-white 85.716%, $color-white 100%);
    }
}

.home {
    &__title {
        font-size: $font-size--1;
        font-weight: $font-weight-light;
        text-transform: uppercase;
        color: $color-text;
        margin: 0;

        &__wave {
            &--main {
                svg {
                    fill: $color-main;
                }
            }
            &--third {
                svg {
                    fill: $color-third;
                }
            }
            &--white {
                svg {
                    fill: $color-white;
                }
            }

            &::after {
                display: none;
            }

            svg {
                width: 155px;
                height: 10px;
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }
    }

    &__button {
        height: 50px;
        margin: 0;
    }
}

.shape--rounded {
    position: relative;

    &::before {
        content: '';
        width: 2500px;
        height: 800px;
        background: $color-white;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        clip-path: $clip-path;
    }
}

[class*="--notimg"] {
    background: $color-bg--image;
    background-image: url(/wp-content/themes/noyau/assets/images/icon-post.png);
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        background: $gradient-alu;
        position: absolute;
        left: 0;
        top: 0;
        pointer-events: none; 
        z-index: 1;
    }

    > .container {
        position: relative;
    }

    .container {
        &--content {
            position: relative;
            height: 100%;
        }

        &--navigation, &--pagination {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            pointer-events: none
        }
    }

    .slideshow {
        height: 745px;
        width: 100%;
        z-index: unset;

        .swiper-wrapper {
            z-index: unset;
        }

        img, video {
            position: absolute;
            object-fit: cover;
            object-position: top;
            width: 100%;
            height: 100%;
        }

        &__container {
            display: inherit;
            position: relative;
            width: 100%;
            height: 100%;
        }

        &__content {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            position: absolute;
            right: 30px;
            bottom: 50px;
            width: 500px;
            height: auto;
            padding: 20px;
            background: $color-second--rgba;
            color: $color-white;
        }

        &__title {
            margin-bottom: 15px;
            font-size: $font-size--4;
            font-weight: $font-weight-bold;
        }

        &--video {
            img {
                display: none;
            }
        }

        // Navigation (arrow)
        &__navigation {
            display: none;
            position: absolute;
            right: 0;
            left: 0;
            top: 0;
            height: 100%;
            pointer-events: none;
    
            &__btn {
                pointer-events: auto;
                width: 40px;
                height: 40px;
                margin: 0;
                padding: 0;
                background: $color-main;
                border: 0;
                border-radius: 50px;
    
                &::after {
                    position: relative;
                    font-size: $font-size--text;
                    font-weight: $font-weight-bold;
                    color: $color-white;
                }
    
                &:hover {
                    background-color: $color-second;
    
                    &::after {
                        color: $color-main;
                    }
                }

                &--prev {
                    left: 15px;
                }

                &--next {
                    right: 15px;
                }
            }
        }

        // Pagination (bullet)
        &__pagination {
            // display: flex;
            display: none;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 15px;
            bottom: 30px;
            z-index: 2;
            width: auto;
    
            .swiper-pagination-bullet {
                width: 10px;
                height: 10px;
                margin: 0 10px 0 0;
                transition: .25s;
                background-color: $color-white;
                border-radius: 5px;
                opacity: 1;
    
                &:hover {
                    background-color: $color-main;
                }
    
                &-active {
                    width: 40px;
                    background-color: $color-main;
                }
            }
        }
    
    }
}


.zenviron__link {
    position: absolute;
    bottom: -325px;
    right: calc(((100vw - 1200px) / 2) - 835px + 245px);
    width: 835px;
    height: 775px;
    filter: $shadow-map-alu;
    -webkit-filter: $shadow-map-alu;

    svg {
        width: 100%;
        height: 100%;
    }

    span {
        position: absolute;
        background: $color-main;
        border: 2px solid $color-main;
        color: $color-white;
        text-transform: uppercase;
        font-weight: $font-weight-medium;
        font-family: $font-family--heading;
        font-size: $font-size--text-small;
        line-height: 0.9;
        padding: 17px 25px;
        top: 355px;
        left: 30px;
        z-index: 10;
        transition: all $duration;
    }

    &:hover span {
        padding: 17px 35px;
    }

}

//======================================================================================================
// Acces Rapides
//======================================================================================================

.home-access {
    position: absolute;
    bottom: 45px;
    left: calc((100vw - 1200px) / 2 + 60px);
        z-index: 10;

    &__list {
        display: flex;
        flex-direction: column;
    }

    &__item {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__link {
        position: relative;
        width: 140px;
        height: 140px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        line-height: 1.2;
        border-radius: 50%;
        background: $color-main;
        color: $color-white;
        text-transform: uppercase;
        font-family: $font-family--heading;
        font-weight: $font-weight-bold;
        font-size: $font-size--text-medium;
        transition: all .5s;

        &::before {
            content: '';
            background: $color-main;
            z-index: -1;
            width: 0;
            height: 100%;
            position: absolute;
            right: 50%;
            border-radius: 100px 0 0 100px;
            transition: all .5s;
        }

        &:hover {
            padding-right: 40px;
            color: $color-white;

            &::before {
                width: 1000px;
            }
        }
    }
}

//======================================================================================================
// Actualités
//======================================================================================================

.home .news {
    padding: 75px 0 65px;
    background: $color-second;
    overflow: hidden;

    .shape--rounded::before {
        left: 150px;
    }

    &__header {
        margin-bottom: 50px;
        position: relative;
    }

    &__navigation {
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 150px;
        height: 70px;

        .swiper-button-prev,
        .swiper-button-next {
            background: transparent;
            position: relative;
            top: unset;
            left: unset;
            right: unset;
            margin: 0;
            border-radius: 50px;
            width: 70px;
            height: 70px;
            transition: all .25s;

            &::after {
                display: none;
            }

            &:hover {
                background: $color-white;
            }
        }
    }

    &__title {
        margin-left: 220px;

        svg {
            display: none;
        }
    }

    &__button {
        position: absolute;
        top: 0;
        right: 15px;
    }

    .swiper-container {
        width: calc(100% + 30px);
        padding: 20px;
        margin-left: -20px;
        margin-top: -20px;
    }

    &__list {
        display: flex;
    }

    &__item {
        width: 370px;
        height: calc(460px + 25px); // padding to avoid overflow hidden on news arrows
        margin-right: 30px;

        &__link {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: calc(100% - 25px);
            box-shadow: $shadow;
            position: relative;
            box-sizing: border-box;
            background: $color-white;

            &:hover {
                box-shadow: $shadow--hover;
            }
        }

        &__image__container {
            width: 100%;
            height: 225px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all .25s;
            }
        }

        &__infos {
            padding: 30px;
            height: 235px;

            &__title {
                font-size: $font-size--3;
                font-weight: $font-weight-medium;
                color: $color-text;
                margin: 0;
            }

            &__introduction {
                margin: 15px 0 0 0;
            }

            &__more {
                width: 50px;
                height: 50px;
                background: $color-second;
                border-radius: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                bottom: -25px;
                right: 25px;

                svg {
                    width: 18px;
                    height: 18px;
                }
            }
        }

        &:hover {
            .news__item__image__container img {
                transform: scale(1.1);
            }
        }
    }
}


//======================================================================================================
// Admin area 1 - Vie économique
//======================================================================================================

.admin-area--1 {
    background-color: $color-main;
    color: $color-white;

    &.border {
        background-image: none;
    }

    .container {
        position: relative;
    }

    &__title {
        color: $color-white;
        font-size: 2.5rem;

        strong {
            font-weight: $font-weight-bold;
        }
    }

    .bloc {
        padding: 51px 0;
        height: 327px;
        max-width: 700px;

        &__image {
            position: absolute;
            top: 0px;
            height: 100%;
            width: calc(((100vw - 1170px) / 2) + 470px); // sur la maquette l'image se situe à 470px du bord droit du container, et doit être collé au bord droit de l'écran
            // on calcule donc la largeur des bords perdus en dehors du container principal, on divise par 2 pour avoir la largeur du bord perdu de droite auquel on ajoute 470px
            right: calc(((100vw - 1170px) / -2) + 15px); // + 15px de padding du container

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__content {
            max-width: 600px;
        }

        &__description {
            margin: 18px 0 20px;
        }

        &__link {
            height: 50px;
            margin: 0;

            &:hover {
                color: $color-white;
            }
        }
    }
}


//======================================================================================================
// Agenda
//======================================================================================================

.home .events {
    background: $color-third;
    padding: 80px 0;
    overflow: hidden;

    &__container::before {
        right: 150px;
    }

    &__list {
        height: 432px;
        margin-top: 60px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
        align-content: space-between;
        position: relative;
    }

    &__item {
        width: 600px;
        height: 190px;
        background: $color-white;
        position: relative;

        &__link {
            color: $color-text;
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            box-shadow: $shadow;

            &:hover {
                box-shadow: $shadow--hover;
            }
        }

        &__image__container {
            width: 230px;
            height: 100%;
            position: relative;
            overflow: hidden;

            &::before {
                content: '';
                background: $color-third;
                width: 250px;
                height: 250px;
                border-radius: 50%;
                position: absolute;
                left: -165px;
                top: 50%;
                transform: translateY(-50%);
                transition: all $duration;
            }            

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__infos {
            width: 370px;
            padding: 30px;
            height: 100%;
            display: flex;
            align-items: center;
        }

        &__title {
            color: $color-text;
            font-size: 26px;
            font-weight: 500;
            margin: 0;
        }

        &__dates {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: 20px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);

            svg {
                width: 14px;
                height: 8px;
                margin: 8px 0 4px;
            }

            p {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-transform: uppercase;
                font-family: $font-family--heading;
            }

            &__day {
                font-weight: $font-weight-semibold;
                font-size: 1.875rem;
                line-height: 1.2;
            }

            &__month {
                font-weight: $font-weight-medium;
                font-size: 0.938rem;
                line-height: 1;
            }
            
            &.events__item__date__same {
                margin-left: 15px;

                .events__item__dates {
                    &__day {
                        font-size: $font-size--2;
                    }

                    &__month {
                        font-size: $font-size--4;
                    }
                }
            }
        }

        &--big {
            width: 500px;
            height: 100%;

            .events__item {

                &__link {
                    flex-direction: column;
                }

                &__image__container {
                    width: 100%;
                    height: 295px;

                    &::before {
                        width: 320px;
                        height: 320px;
                        left: -184px;
                    }
                }

                &__infos {
                    height: 137px;
                    width: 100%;

                    &__title {
                        font-size: 1.875rem;
                    }
                }

                &__dates {
                    top: calc(50% - 76px);
                    margin-left: 34px;

                    &__day {
                        font-size: $font-size--2;
                    }

                    &__month {
                        font-size: $font-size--4;
                    }
                }
                
            }
        }

        &:hover .events__item__image__container::before {
            transform: translateY(-50%) scale(1.1);
            margin: 20px 0 0px 10px;
        }
    }

    &__buttons {
        position: absolute;
        top: 10px;
        right: 15px;

        .button {
            box-shadow: $shadow;

            &:first-child {
                margin-right: 30px;
                background: $color-third;
                border-color: $color-third;

                &:hover {
                    background: transparent;
                }
            }

            &:last-child {
                background: $color-white;
                border-color: $color-white;

                &:hover {
                    background: $color-main;
                    border-color: $color-main;
                    color: $color-white;
                }
            }
        }
        
    }
}

//======================================================================================================
// Kiosque
//======================================================================================================

.documents__zoomsur {
    background-color: $color-second;
    background-size: 24px 100%;

    &__container {
        display: flex;
        position: relative;
    }
}

.home .documents {
    padding: 60px 0 50px;

    &__title {
        margin: 0 0 50px 0;
    }

    &__item {
        display: flex;
        align-items: center;

        &:nth-child(2) {
            margin: 30px 0 40px;
        }

        &__image {
            width: 130px;
            height: 184px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__content {
            margin-left: 30px;
        }

        &__title {
            font-size: $font-size--4;
            font-weight: $font-weight-medium;
            color: $color-text;
            max-width: 310px;
            line-height: 1.1;
            margin: 0;
        }

        &__infos {
            margin: 10px 0 15px;
            font-size: $font-size--text-small;
        }

        &__extension {
            text-transform: uppercase;
        }

        &__buttons {
            display: flex;

            a {
                margin: 0 20px 0 0;

                &:last-child {
                    margin: 0;
                }

                svg {
                    width: 50px;
                    height: 50px;
                    margin-right: 0;
                }
            }
        }
    }
}

//======================================================================================================
// Admin area 2 - Zoom Sur
//======================================================================================================

.admin-area--2 {
    &__header {
        text-shadow: 0px 0px 10px $color-text;
    }

    &__title {
        color: $color-white;
        display: inline-flex;
    }

    .home__title__wave svg {
        right: -30px;
        left: unset;
        width: 405px;
        height: 26px;
        bottom: -18px;
    }

    .bloc {
        &__content {
            max-width: 300px;
            position: absolute;
            right: 270px;
            top: 50%;
            transform: translateY(-50%);
        }

        &__title {
            color: $color-white;
            text-shadow: 0px 0px 10px $color-text;
            margin: 40px 0 25px;
        }

        &__image {
            position: absolute;
            width: calc(((100vw - 1170px) / 2) + 670px);// sur la maquette l'image se situe à 670px du bord droit du container, et doit être collé au bord droit de l'écran
            // on calcule donc la largeur des bords perdus en dehors du container principal, on divise par 2 pour avoir la largeur du bord perdu de droite auquel on ajoute 670px
            right: calc(((100vw - 1170px) / -2) + 15px); // + 15px de padding du container
            height: 100%;
            -webkit-clip-path: ellipse(670px 800px at 700px 50%);
            clip-path: ellipse(670px 850px at 700px 50%);

            &::before {
                content: '';
                background: $gradient-zoomsur;
                width: 100%;
                height: 100%;
                position: absolute;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    
    .button {
        margin: 0;
        height: 50px;

        &:hover {
            background: $color-second;
            border-color: $color-second;
            color: $color-text;
        }
    }
}

//======================================================================================================
// Admin area 3 - Retour en images
//======================================================================================================

.admin-area--3 {
    background: $color-third;
    padding: 70px 0;
    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        background: $color-white;
        width: 3000px;
        height: 1000px;
        position: absolute;
        bottom: 340px;
        left: 50%;
        transform: translateX(-50%);
        -webkit-clip-path: ellipse(80% 100% at 50% 0%);
        clip-path: ellipse(80% 100% at 50% 0%);
        z-index: 0;
    }

    .container {
        position: relative;
    }

    &__title {
        text-align: center;

        svg {
            width: 155px;
            height: 10px;
            left: 50%;
            transform: translateX(-50%);
            bottom: -5px;
        }
    }

    .admin-blocs {
        display: flex;
        justify-content: space-between;
        margin-top: 60px;

        .bloc {
            &__image {
                width: 370px;
                height: 315px;
                overflow: hidden;
                
                img {
                    transition: all $duration;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                
                &:hover {
                    img {
                        transform: scale(1.1);
                    }
                }
            }

            &:nth-child(2) {
                .bloc__image {
                    width: 225px;
                    height: 330px;
                    margin-top: 40px;
                }
            }
        }
    }
}

//======================================================================================================
// Labels & Partenaires
//======================================================================================================

.partenaires {
    padding: 70px 0 50px;

    &__title {
        text-align: center;
        font-size: 1.875rem;
        margin-bottom: 30px;
        padding: 0;

        &::after {
            display: none;
        }
    }

    &__list {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    &__item {
        margin: 20px 30px;

        &__link:hover {
            opacity: 0.6;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &:nth-child(1) {
            margin-left: 270px;
            width: 100px;
            height: 91px;
        }

        &:nth-child(2) {
            width: 151px;
            height: 55px;
        }

        &:nth-child(3) {
            width: 90px;
            height: 93px;
        }

        &:nth-child(4) {
            margin-right: 270px;
            width: 90px;
            height: 90px;
        } 

        &:nth-child(5) {
            width: 140px;
            height: 63px;
        }

        &:nth-child(6) {
            width: 143px;
            height: 59px;
        }

        &:nth-child(7) {
            width: 212px;
            height: 81px;
        }

        &:nth-child(8) {
            width: 74px;
            height: 77px;
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    // Start ALU 1200
    .home-slideshow {    
        .slideshow {
            height: 700px;
        }
    }
    
    .zenviron__link {
        right: calc(((100vw - 960px) / 2) - 835px + 225px);
    }
    // End ALU 1200
    
    // Start Acces Rapides 1200
    .home-access {
        left: calc((100vw - 960px) / 2);
    }
    // End Acces Rapides 1200

    // Start Actualités 1200
    .home .news {

        .swiper-container {
            width: calc(100% + 15px);
            padding: 15px;
            margin-left: -15px;
            margin-top: -15px;
        }

        &__item {
            width: 300px;
            margin-right: 20px;

            &__image__container {
                height: 185px;
            }
        }        
    }
    // End Actualités 1200

    // Start Admin Area 1 - Vie économique 1200
    .admin-area--1 {
        .bloc {
            max-width: 560px;

            &__image {
                width: calc(((100vw - 940px) / 2) + 380px);// sur la maquette l'image se situe à 380px du bord droit du container, et doit être collé au bord droit de l'écran
                // on calcule donc la largeur des bords perdus en dehors du container principal, on divise par 2 pour avoir la largeur du bord perdu de droite auquel on ajoute 380px
                right: calc(((100vw - 940px) / -2) + 10px); // + 10px de padding du container
            }

            &__content {
                max-width: 470px;
            }
        }
    }
    // End Admin Area 1 - Vie économique 1200

    // Start Agenda 1200
    .home .events {
        &__list {
            height: 430px;
        }

        &__item {
            width: 540px;

            &--big {
                width: 380px;

                .events__item {

                    &__image__container {
                        height: 225px;
                    }
                    
                    &__infos {
                        height: 205px;
                    }

                    &__dates {
                        top: calc(50% - 102px);
                    }

                }
            }
        }
        
    }
    // End Agenda 1200

    // Start Kiosque 1200
    .home .documents {

        &__item {

            &__title {
                max-width: 240px;
            }

        }
    }
    // End Kiosque 1200

    // Start Admin Area 2 - Zoom Sur 1200
    .admin-area--2 {

        .home__title__wave svg {
            right: 50%;
            transform: translateX(50%);
        }

        .bloc {
            &__content {
                right: 150px;
            }

            &__image {
                width: calc(((100vw - 940px) / 2) + 500px);// sur la maquette l'image se situe à 500px du bord droit du container, et doit être collé au bord droit de l'écran
                // on calcule donc la largeur des bords perdus en dehors du container principal, on divise par 2 pour avoir la largeur du bord perdu de droite auquel on ajoute 500px
                right: calc(((100vw - 940px) / -2) + 10px); // + 10px de padding du container
                -webkit-clip-path: ellipse(500px 800px at 520px 50%);
                clip-path: ellipse(500px 800px at 520px 50%);
            }
        }
    }
    // End Admin Area 2 - Zoom Sur 1200

    // Start Admin Area 3 - Retour en images 1200
    .admin-area--3 {
        &::before {
            bottom: 280px;
        }

        .admin-blocs .bloc {
            &__image {
                width: 320px;
                height: 270px;
            }
            
            &:nth-child(2) .bloc__image {
                width: 195px;
                height: 285px;
                margin-top: 35px;
            }
        }
    }
    // End Admin Area 3 - Retour en images 1200

    // Start Labels & partenaires 1200
    .partenaires {
        &__item {

            &:nth-child(1) {
                margin-left: 160px;
            }
    
            &:nth-child(4) {
                margin-right: 160px;
            }
        }
    }
    // End Labels & partenaires 1200
    
}


// 960
@media screen and (max-width: $medium) {

    .shape--rounded:before {
        top: 180px;
        left: 50%;
        transform: translateX(-50%);
        clip-path: ellipse(50% 50% at 50% 50%);
    }

    // Start ALU 960
    .home-slideshow {
        position: relative;

        .slideshow {
            height: 490px;

            &--video {
                video {
                    display: none;
                }

                img {
                    display: block;
                }
            }
        }
    }

    .zenviron__link {
        width: 540px;
        height: 500px;
        bottom: calc(220px - 135px);
        right: calc(((100vw - 640px) / 2) - 540px + 200px);
    }
    
    .zenviron__link span {
        top: 270px;
        left: 10px;
    }
    // End ALU 960

    // Start Acces Rapides 960
    .home-access {
        position: relative;
        left: unset;
        bottom: unset;
        z-index: 20;

        &::before {
            content: '';
            background: $color-white;
            width: 100%;
            height: 100%;
            position: absolute;
        }

        &__list {
            flex-direction: row;
            justify-content: center;
        }

        &__item {
            margin: 40px 30px 40px 0px;

            &:last-child {
                margin: 40px 0 40px 0;
            }
        }

        &__link:hover {
            padding-right: 0;
        }
    }
    // End Acces Rapides 960

    // Start Actualités 960
    .home .news {
        padding: 55px 0 50px;

        .swiper-container {
            width: calc(100% + 20px);
            padding: 15px;
            margin-left: -15px;
            margin-top: -15px;
        }

        &__header {
            display: flex;
            justify-content: center;
        }

        &__navigation {
            width: 100%;
        }

        &__title {
            margin: 0;

            &::after {
                display: none;
            }

            svg {
                display: block;
                fill: $color-white;
                width: 155px;
                height: 10px;
                position: absolute;
                bottom: -15px;
                right: 50%;
                transform: translateX(50%);
            }
        }

        &__buttons {
            display: flex;
            justify-content: center;
            margin-top: 30px;

            .button {
                position: relative;
            }
        }
    }
    // End Actualités 960

    // Start Admin Area 1 - Vie économique 960
    .admin-area--1 {
        &.border {
            background-image: linear-gradient(to right, $color-second,$color-second 14.286%,transparent 0,transparent 28.572%,$color-second 0,$color-second 42.858%,transparent 0,transparent 57.144%,$color-second 0,$color-second 71.43%,transparent 0,transparent 85.716%,$color-second 0,$color-second);
            background-position: top center;
            background-size: 340px 19px;
        }
        
        &__title::after {
            left: 50%;
            transform: translateX(-50%);
        }

        .bloc {
            max-width: 620px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin-top: 300px;

            &__image {
                width: 100vw;
                right: 50%;
                transform: translateX(50%);
                top: -300px;
                height: 300px;
            }

            &.border {
                background-image: none;
            }
        }
    }
    // End Admin Area 1 - Vie économique 960

    // Start Agenda 960
    .home .events {
        padding: 60px 0;

        &__title {
            text-align: center;

            svg {
                fill: $color-white;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &__list {
            height: auto;
            flex-direction: row;
        }

        &__item {
            width: 300px;
            height: 390px;

            &__link {
                flex-direction: column;
            }

            &__image__container {
                width: 100%;
                height: 190px;
            }

            &__infos {
                width: 100%;
                height: 200px;
                padding: 20px;
            }

            &__dates {
                top: calc(50% - 100px);
            }

            &--big {
                width: 100%;
                height: 225px;
                margin-bottom: 30px;

                .events__item {

                    &__link {
                        flex-direction: row;
                    }

                    &__image__container {
                        width: 300px;
                        height: 100%;
                    }

                    &__infos {
                        width: 320px;
                        height: 100%;
                    }

                    &__dates {
                        top: 50%;
                    }
                }
            }
        }

        &__buttons {
            position: relative;
            top: unset;
            right: unset;
            margin-top: 60px;
            display: flex;
            justify-content: center;

            .button:first-child {
                margin-right: 20px;
            }
        }
    }    
    // End Agenda 960

    // Start Kiosque 960
    .documents__zoomsur {
        background-position: top;
        background-size: 340px 19px;
        background-image: linear-gradient(to right, $color-main,$color-main 14.286%,transparent 0,transparent 28.572%,$color-main 0,$color-main 42.858%,transparent 0,transparent 57.144%,$color-main 0,$color-main 71.43%,transparent 0,transparent 85.716%,$color-main 0,$color-main);
    
        &__container {
            flex-direction: column;
        }
    }

    .home .documents {
        padding: 60px 0 calc(60px + 725px);

        &__title {
            text-align: center;

            svg {
                right: 50%;
                transform: translateX(50%);
                left: unset;
                bottom: -10px;
            }
        }

        &__list {
            display: flex;
            justify-content: space-between;
        }

        &__item {
            flex-direction: column;
            text-align: center;

            &:nth-child(2) {
                margin: 0;
            }

            &__content {
                margin: 0;
            }

            &__title {
                max-width: 300px;
                min-height: 48px;
                margin-top: 20px;
            }

            &__buttons {
                justify-content: center;
            }
        }

        &__buttons {
            display: flex;
            justify-content: center;

            a {
                margin: 50px 0 0 0;
                display: inline-block;
            }
        }
    }
    // End Kiosque 960

    // Start Admin Area 2 - Zoom Sur 960
    .admin-area--2 .bloc {
        &__image {
            bottom: 0;
            height: 725px;
            width: 100vw;
            right: 50%;
            transform: translateX(50%);
            -webkit-clip-path: ellipse(120% 60% at 50% 60%);
            clip-path: ellipse(120% 60% at 50% 60%);
        }

        &__content {
            top: 1050px;
            right: unset;
            left: 10px;
        }
    }
    // End Admin Area 2 - Zoom Sur 960

    // Start Admin Area 3 - Retour en images 960
    .admin-area--3 {
        padding: 70px 0 60px;

        &::before {
            bottom: 200px;
        }

        .admin-blocs .bloc {
            &__image {
                width: 211px;
                height: 178px;
            }
            
            &:nth-child(2) .bloc__image {
                width: 129px;
                height: 188px;
                margin-top: 25px;
            }
        }
    }
    // End Admin Area 3 - Retour en images 960

    // Start Labels & partenaires 960
    .partenaires {
        padding: 60px 0 40px;
    
        &__item {
            margin: 20px 25px;
    
            &:nth-child(1) {
                margin-left: 60px;
                width: 81px;
                height: 73px;
            }
    
            &:nth-child(2) {
                width: 121px;
                height: 43px;
            }
    
            &:nth-child(3) {
                width: 73px;
                height: 75px;
            }
    
            &:nth-child(4) {
                margin-right: 60px;
                width: 75px;
                height: 75px;
            }
    
            &:nth-child(5) {
                margin-left: 0;
                width: 114px;
                height: 52px;
            }
    
            &:nth-child(6) {
                width: 116px;
                height: 48px;
            }
    
            &:nth-child(7) {
                width: 172px;
                height: 66px;
            }
    
            &:nth-child(8) {
                margin-right: 0;
                width: 60px;
                height: 62px;
            }
        }
    }
    // End Labels & partenaires 960

}


// 640
@media screen and (max-width: $small) {

    // Start ALU 640
    .home-slideshow {
        .slideshow {
            height: 440px;
            
            &__content {
                left: 30px;
                right: 30px;
                width: auto;
            }
        }
    }

    .zenviron__link {
        width: 365px;
        height: 345px;
        right: calc(((100vw - 320px) / 2) - 365px + 140px);
        bottom: 40px;

        span {
            top: 160px;
            left: -110px;
        }
    }
    // End ALU 640

    // Start Acces Rapides 640
    .home-access {
        &__item {
            margin: 30px 20px 30px 0;

            &:last-child {
                margin: 30px 0 30px 0;
            }
        }

        &__link {
            width: 85px;
            height: 85px;
            font-size: 14px;
            line-height: 1.4;

            svg {
                width: 41px;
                height: 35px;
            }
        }
    }
    // End Acces Rapides 640

    // Start Actualités 640
    .home .news {

        &__navigation {
            height: 40px;
        }

        &__title {
            font-size: 2.125rem;
        }

        .swiper-button-next,
        .swiper-button-prev {
            width: 40px;
            height: 40px;
        }
    }
    // End Actualités 640

    // Start Admin Area 1 - Vie économique 640
    .admin-area--1 {
        &__title {
            font-size: 2.125rem;
        }

        .bloc {
            max-width: 300px;
            margin-top: 200px;

            &__image {
                top: -200px;
                height: 200px;
            }
        }
    }
    // End Admin Area 1 - Vie économique 640

    // Start Agenda 640
    .home .events {

        &__container:before {
            height: 2000px;
        }

        &__item--big {
            height: 450px;
            margin-bottom: 40px;

            .events__item {

                &__link {
                    flex-direction: column;
                }

                &__image__container {
                    height: 225px;
                }

                &__infos {
                    height: 225px;
                    width: 300px;
                }

                &__dates {
                    top: calc(50% - 115px);
                }
            }
        }

        &__buttons {
            margin-top: 40px;
            flex-direction: column;
            align-items: center;

            .button:first-child {
                margin: 0 0 20px;
            }
        }

    }
    // End Agenda 640

    // Start Kiosque 640
    .home .documents {
        padding: 60px 0 calc(45px + 490px);

        &__title {
            font-size: 2.125rem;
            line-height: 1.5;
        }

        &__list {
            flex-direction: column;
        }

        &__item {
            &:nth-child(2) {
                margin-top: 40px;
            }

            &__buttons a:last-child {
                margin-right: 0;
            }
        }

        &__buttons a {
            margin: 40px 0 0 0;
        }
        
    }    
    // End Kiosque 640

    // Start Admin Area 2 - Zoom Sur 640
    .admin-area--2 {

        &__title {
            font-size: 2.125rem;
            line-height: 1.5;
        }

        .home__title__wave svg {
            width: 154px;
            height: 10px;
            bottom: -10px;
        }

        .bloc {
            &__content {
                top: 1140px;
                transform: none;
                text-align: center;
            }

            &__image {
                height: 490px;

                &:before {
                    background: $gradient-zoomsur--mobile;
                }
            }
        }
    }
    // End Admin Area 2 - Zoom Sur 640

    // Start Admin Area 3 - Retour en images 640
    .admin-area--3 {
        padding: 50px 0 60px;

        &:before {
            bottom: 320px;
            -webkit-clip-path: ellipse(60% 100% at 50% 0);
            clip-path: ellipse(60% 100% at 50% 0);
        }

        &__title {
            font-size: 2.125rem;
        }

        .admin-blocs {
            margin-top: 50px;
            flex-direction: column;
            align-items: center;

            .bloc:nth-child(2) .bloc__image {
                margin: 25px 0;
            }
        }
    }    
    // End Admin Area 3 - Retour en images 640

    // Start Labels & partenaires 640
    .partenaires {
        padding: 60px 0;

        &__title {
            margin-bottom: 25px;
        }
    
        &__item {
            margin: 0;
    
            &:nth-child(1) {
                margin: 0 60px 16px 19px;
            }
    
            &:nth-child(2) {
                margin: 0 19px 16px 0;
            }
    
            &:nth-child(3) {
                margin: 0 30px 42px 20px;
            }
    
            &:nth-child(4) {
                margin: 0 20px 42px 30px;
            }
    
            &:nth-child(5) {
                margin: 0 50px 48px 10px;
            }
    
            &:nth-child(6) {
                margin: 0 10px 48px 0;
            }
    
            &:nth-child(7) {
                margin: 0 50px 0 0;
            }
    
            &:nth-child(8) {
                margin: 0 18px 0 0;
            }
        }
    }
    // End Labels & partenaires 640

}
