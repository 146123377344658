.header {
    position: relative;
    margin-bottom: -104px; // header height
    z-index: 25;
    
    .identity {
        line-height: 0;
        position: absolute;
        left: 45px;
        top: 20px;
        
        &__title {
            font-size: 0;

            svg:first-child {
                filter: $filter-shadow;
                -webkit-filter: $filter-shadow;
            }
            svg:last-child {
                display: none;
            }
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
        position: relative;
    }

    &__top {
        display: flex;
        align-items: center;

        button,
        a {
            font-size: $font-size--text;
            line-height: $line-height--heading;
            font-weight: $font-weight-medium;
            font-family: $font-family--heading;
            color: $color-white;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 17px 20px;
            margin: 0;
            text-transform: none;
            background: none;
            border: none;
            position: relative;
            text-shadow: $text-shadow;

            &::after {
                content: '';
                width: 0;
                height: 2px;
                background: $color-white;
                position: absolute;
                bottom: 12px;
                left: 20px;
                transition: width $duration ease-in-out;
            }

            &:hover, &:focus {
                color: $color-white;

                &::after {
                    width: calc(100% - 40px);
                }
            }
        }

    }

    &__bottom {
        z-index: 10;
    }

    &--fixed {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        padding: 0;
        background-color: $color-main;
        box-shadow: $shadow;
        z-index: 20;
        height: 70px;

        .header {

            &__container {
                align-items: unset;
            }

            &__top {
                a, button {
                    display: none;
                }
                
                .tools__search {
                    display: block;
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 20;
                    font-size: $font-size--text-small;
                }
            }

            &__bottom {
                height: 70px;
                display: flex;
                align-items: center;
                margin-left: 185px;
            }

        }

        .identity {
            top: 50%;
            transform: translateY(-50%);

            svg {
                &:first-child {
                    display: none;
                }
                &:last-child {
                    display: inline;
                    width: 105px;
                    height: 44px;
                }
            }
        }
    }
}

.admin-bar .header--fixed {
    top: 32px;
}

//======================================================================================================
// Tools
//======================================================================================================
#uci_link{
    display: none;
}

body:not(.home) {
    .tools {
        &__content {
            & > svg {
                fill: $color-bg--neutral;
            }
        }
        
        &::before {
            background: $color-bg--neutral;
        }
    }
}

.tools {

    &__content {
        background: $color-bg--transparent;
        display: flex;
        align-items: center;

        & > svg {
            fill: $color-white; 
        }
    }
    
    svg {
        fill: $color-white;
        transition: all $duration;
        width: 20px;
        height: 20px;
    }

    .tool {
        &:hover {
            cursor: pointer;

            svg {
                fill: $color-white;
            }
        }
    }

    &__reseau.tool {
        filter: $filter-shadow;
        -webkit-filter: $filter-shadow;

        &::after {
            display: none;
        }

        &:nth-of-type(1) {
            padding-right: 10px;
        }

        &:nth-of-type(2) {
            padding: 17px 15px;
        }

        &:nth-of-type(3) {
            padding-left: 10px;
        }

        &:hover {
            svg {
                fill: $color-second;
            }
        }

    }

    // &__translate {
    //     svg {
    //         display: none;
    //     }
    // }

    // Google translate
    &__translate {
        position: relative;

        > svg {
            display: none;
        }

        &__wrapper {
            position: absolute;
            left: 50%;
            top: 55px;
            z-index: 20;
            display: none;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
            gap: 10px;
            width: max-content;
            padding: 15px 20px 30px;
            background-color: $color-white;
            border-radius: $border-radius;
            transform: translateX(-50%);
            filter: drop-shadow(0px 0px 1px rgba(27, 35, 54, 0.08)) drop-shadow(1px 1px 10px rgba(27, 35, 54, 0.08));
        
            &.js-open {
                display: flex;
            }

            &::before {
                content: '';
                position: absolute;
                left: 50%;
                top: -6px;
                display: block;
                width: 30px;
                height: 30px;
                background-color: $color-white;
                border-radius: 5px;
                transform: translateX(-50%) rotate(45deg);
                pointer-events: none;
                z-index: -1;
            }

            a.glink {
                position: relative;
                font-family: $font-family;
                font-size: $font-size--text;
                font-weight: $font-weight;
                color: $color-text;
                display: block;
                padding: 0;
                text-shadow: none;
        
                &:hover, &:focus {
                    text-decoration: underline;
                }
        
                &.gt-current-lang {
                    font-weight: $font-weight-bold;
                    text-decoration: underline;
                }

                &::before, &::after {
                    display: none !important;
                }
            }
        }

        &__close {
            position: absolute !important;
            bottom: -10px;
            left: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateX(-50%);
            padding: 0 !important;
            margin: 0 !important;
            background-color: $color-main !important;
            border: 0;
            border-radius: $border-radius--round;
            transition: background-color $duration ease-in-out;

            svg {
                width: 26px;
                height: 26px;
                fill: $color-white;
                transition: fill $duration ease-in-out;
            }

            &:hover, &:focus {
                background-color: $color-second !important;

                svg {
                    fill: $color-text;
                }
            }
        }
    }

    &__accessibility {
        svg {
            display: none;
        }
    }

    &__search {
        &--disabled {
            pointer-events: none;
            opacity: .2;
        }

        &.tool {
            padding-right: 0;

            svg {
                width: 13px;
                height: 13px;
                margin-right: 5px;
                filter: $filter-shadow;
                -webkit-filter: $filter-shadow;
            }

            &:hover {
                &::after {
                    width: calc(100% - 20px);
                }
            }
        }
    }
}

.tools-view {
    position: absolute;
    top: 165px;
    width: 100%;
    z-index: 1;

    .container--tools-view {
        display: flex;
        justify-content: flex-end;

        #google_translate_element {
            display: none;
        }

        .profils {
            position: relative;
            display: none;
            width: 240px;
            height: auto;
            margin-top: 45px;
            margin-right: 60px;
            padding: 15px 20px 20px;
            background: $color-second;

            &.show-list {
                display: flex;
                flex-direction: column;
            }

            &__title {
                color: $color-white;
                text-transform: uppercase;
                margin: 20px 0 15px;
            }

            &__list {
                li {
                    a {
                        position: relative;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        padding: 10px 20px 10px 0px;
                        border-top: 1px solid $color-white;
                        color: $color-white;
                        transition: all $duration;

                        &:hover {
                            padding-left: 10px;
                        }

                        &::after {
                            content: "\f344";
                            font: normal 13px/1 dashicons;
                            display: flex;
                            align-items: center;
                            position: absolute;
                            right: 0;
                            height: 100%;
                            color: $color-white;
                        }
                    }

                    &:last-child {
                        a {
                            border-bottom: 1px solid $color-white;
                        }
                    }
                }
            }
        }
    }
}

iframe.goog-te-menu-frame.skiptranslate {
    position: fixed;
    top: 110px!important;
    left: calc(50% + 25px)!important;
}

//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        color: $color-text;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .header {

        .identity {
            left: 10px;

            &__title svg:first-child {
                width: 140px;
                height: 113px;
            }
        }

        &__top a,
        &__top button {
            padding: 17px 15px;
        }

        &--fixed {
            
            .header {
                
                &__top .tools__search {
                    span,
                    &::after {
                        display: none;
                    }

                    svg {
                        margin: 0;
                        width: 18px;
                        height: 18px;
                    }
                }
                
                &__bottom {
                    margin-left: 90px;
                }
                
            }

            .identity {
                left: 10px;

                svg {
                    &:last-child {
                        width: 90px;
                        height: 38px;
                    }
                }
            }

        }

    }

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute;
        left: calc(50% - 75px)!important;
        top: 110px!important;
    }

}


// 960
@media screen and (max-width: $medium) {

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute;
        left: calc(100% - (100% - 640px)/2 - 335px)!important;
        top: 50px!important;
    }

    .privacy {
        &__container {
            flex-wrap: wrap;
        }

        &__button {
            margin-left: 0;
            margin-top: 20px;
        }
    }

    .header {
        margin-bottom: 0;

        &__top {
            position: relative;
            width: 100%;

            &::before {
                content: '';
                background: $color-main;
                width: 1000px;
                height: 40px;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                z-index: -1;
            }

            a, button {
                padding: 10px 15px;

                &::after {
                    bottom: 7px;
                }
            }
        }

        .identity {
            top: 60px;

            &__title svg:first-child {
                width: 161px;
                height: 129px;
            }
        }
    }

    .tools {
        margin-left: auto;

        &__accessibility,
        &__translate,
        &__search {
            &::after,
            span {
                display: none;
            }

            &.tool:hover {
                svg {
                    fill: $color-second;
                }
            }
        }

        &__accessibility svg,
        &__translate svg {
            display: block;
        }

        &__search.tool {
            svg {
                width: 20px;
                height: 20px;
            }
        }

        &__reseau.tool:nth-of-type(2) {
            padding: 10px 15px;
        }
    }
  
}


// 640
@media screen and (max-width: $small) {

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute!important;
        left: calc(100% - (100% - 300px/2))!important;
        top: 90px!important;
    }

    .header {
        &__top {
            flex-wrap: wrap;
            justify-content: center;

            &::before {
                height: 80px;
            }
        }

        .identity {
            top: 100px;
            left: 50%;
            transform: translateX(-50%);

            &__title svg:first-child {
                width: 149px;
                height: 120px;
            }
        }
    }

    .tools {
        margin-left: 0;
        flex: auto;

        &__content {
            justify-content: space-between;
        }

        &__translate.tool {
            padding-left: 0;
        }

        a, button {
            padding: 5px 0 15px !important;
        }

        .tools__translate__wrapper a.glink, .tools__translate__close {
            padding: 0 !important;
        }
 
        &__reseau.tool:nth-of-type(2) {
            padding: 5px 10px 15px;
        }        
        
    }    

}
