.cover {
    position: relative;

    &__image-wrapper {
        width: 100%;
        height: 600px;
        background: $color-bg--image;
        position: relative;

        &::before {
            content: '';
            width: 100%;
            height: 100%;
            background: $gradient-alu;
            position: absolute;
            left: 0;
            top: 0;
            pointer-events: none;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        background: $color-second;
        padding: 40px 0 50px;
        margin-bottom: 20px;
    }

    &__title {
        color: $color-text;
        margin: 0 0 10px;
    }

    &__lead-wrapper {
        p {
            margin: 20px 0 0;
            font-weight: $font-weight-normal;
            font-size: $font-size--text-medium;
            line-height: 1.3;
        }
    }

    &__intro {
        color: $color-text;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .cover {
        &__image-wrapper {
            height: 480px;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {
        &__lead-wrapper {
            p {
                font-weight: $font-weight;
            }
        }
        
        &__image-wrapper {
            height: 400px;
        }
    }

}
