.footer {
    display: flex;
    flex-direction: column;

    &__top {
        padding: 70px 0;
        position: relative;
        overflow: hidden;
    }

    &__infos {
        position: relative;
        display: flex;
        align-items: center;
        color: $color-white;
        width: 100%;

        &::before, &::after {
            content: '';
            width: 2000px;
            height: 1000px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: -1;
        }

        &::before {
            background: $color-main;
            left: calc(660px - 2000px - 15px);
        }

        &::after {
            background: $color-second;
            right: calc(540px - 2000px - 15px);
        }

        svg {
            fill: $color-white;
            transition: all $duration;
        }

        &__title {
            font-family: 'Raleway';
            font-weight: 600;
            font-size: 22px;
        }

        &__text {
            margin: 10px 0 30px;
        }

        &__logo {
            width: 200px;
            height: 169px;

            svg {
                width: 100%;
                height: 100%;
            }
        }

        &__coordonnees {
            display: flex;
            flex-direction: column;
            width: 270px;
            margin-left: 100px;
            z-index: 2;

            .button {
                margin: 30px 0 0 0;
                padding: 0;
                width: 178px;
                height: 50px;

                &:hover {
                    color: $color-white;
                }
            }
        }

        &__phone {
            position: relative;
            width: fit-content;

            &::before {
                content: '';
                position: absolute;
                bottom: -4px;
                left: 0;
                width: 0px;
                height: 1px;
                background: $color-white;
                transition: width $duration;
            }

            svg {
                width: 13px;
                height: 13px;
            }

            &:hover {
                color: $color-white;

                &::before {
                    width: 100%;
                }
            }
        }

        &__reseaux {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-self: normal;
            position: relative;
            z-index: 1;
            padding: 10px 0;

            &::before {
                content: '';
                background: $color-main;
                width: 260px;
                height: 600px;
                position: absolute;
                right: -100px;
                top: 50%;
                transform: translateY(-50%);
                border-radius: 0 50% 50% 0;
                z-index: -1;
            }

            a:hover {
                svg {
                    fill: $color-second;
                }
            }

            svg {
                width: 30px;
                height: 30px;
            }
            
        }

        &__horaires {
            color: $color-text;
            width: 370px;
            margin-left: auto;

            .footer__infos__text {
                margin: 10px 0 0;
                line-height: 1.35;
            }
        }
    }

    &__menu {
        padding: 30px 0;
        background: $color-white;

        ul {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
        }

        li {
            position: relative;
            padding: 0 20px;

            a {
                &::before {
                    content: '';
                    position: absolute;
                    bottom: -4px;
                    left: 20px;
                    width: 0px;
                    height: 1px;
                    background: $color-main;
                    transition: width $duration;
                }

                &:hover {
                    color: $color-main;

                    &::before {
                        width: calc(100% - 40px);
                    }
                }
            } 

            &:first-child {
                padding-left: 0;

                a:hover {
                    &::before {
                        width: calc(100% - 20px);
                        left: 0;
                    }
                }
            }
        }
    }
}


// 1200
@media screen and (max-width: $large) {
    .footer {
        &__infos {
            &__coordonnees {
                margin-left: 40px;
            }

            &__reseaux:before {
                right: -40px;
            }

            &__horaires {
                width: 320px;
            }
            
        }

        &__menu li {
            padding: 0 17.5px;
        }
    }
}

// 960
@media screen and (max-width: $medium) {

    .footer {
        &__top {
            &.border {
                padding: 40px 0 70px;
                background-image: linear-gradient(to right, $color-main,$color-main 14.286%,transparent 0,transparent 28.572%,$color-main 0,$color-main 42.858%,transparent 0,transparent 57.144%,$color-main 0,$color-main 71.43%,transparent 0,transparent 85.716%,$color-main 0,$color-main);
                background-position: bottom center;
                background-size: 340px 19px;
            }
        }

        &__infos {
            flex-direction: column;
            text-align: center;

            &::before {
                left: 50%;
                transform: translateX(-50%);
                top: -40px;
            }
            
            &::after {
                right: 50%;
                transform: translateX(50%);
                top: 500px;
            }

            &__logo {
                margin-bottom: 50px;
            }
            
            &__coordonnees {
                margin-left: 0;
                align-items: center;
            }
            
            &__horaires {
                width: 300px;
                margin: 90px 0 0 0;
            }

            &__reseaux {
                flex-direction: row;
                justify-content: center;
                margin-top: 35px;
                padding: 0;

                &::before {
                    right: 50%;
                    transform: translateX(50%);
                    top: -60px;
                    width: 960px;
                    border-radius: 0 0 50% 50%;
                    height: 150px;
                }

                a {
                    margin: 0 25px;
                }
            }
        }

        &__menu {
            width: 80%;
            margin: 0 auto;

            ul {
                justify-content: center;
                row-gap: 7.5px;
            }

            li {
                padding: 0 20px;
                font-size: $font-size--text-small;

                &:nth-child(3),
                &:last-child {
                    padding-right: 0;
                }

                &:nth-child(4) {
                    padding-left: 0;
                }
            }
        }
    }

}

// 640
@media screen and (max-width: $small) {

    .footer {

        &__infos__reseaux:before {
            width: 640px;
        }

        &__menu {
            width: 100%;
            margin: 0;

            li {
                padding: 0 20px !important;
            }
        }
    }

}