.breadcrumb {
    background-color: $color-main;

    ul  {
        display: flex;
        flex-wrap: wrap;
        min-height: 60px;
        padding: 12px 0;
    }

    li {
        display: flex;
        align-items: center;
        color: $color-white;
        padding: 0 3px;

        a:hover {
            color: $color-white;
            text-decoration: underline;
        }
    }

    svg {
        width: 26px;
        height: 35px;
        fill: $color-white;
        transform: rotate(-90deg);

        &:last-child {
            display: none;
        }
    }

}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        position: relative;

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 40px;
            width: 100%;
            height: 1px;
            box-shadow: 0px -1px 0 rgb(32 32 34 / 10%);
        }

        ul {
            min-height: 40px;
        }

        li {
            min-height: 30px;
        } 
    }

}
